import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass']
})
export class NavigationComponent {
  userLangStored: string;
  userLang: any;

  ngOnInit() {

    $(".menu-btn").click(function () {
      $(".mobile-menu-links").removeClass("move-menu");
      $(".nav").removeClass("darken-menu");
      $(".menu-mobile").removeClass("cross-transform");
    });

    // Menu
    $(".menu-mobile").click(function () {
      $(".mobile-menu-links").toggleClass("move-menu");
      $(".nav").toggleClass("darken-menu");
      $(".menu-mobile").toggleClass("cross-transform");
    });
  }

  constructor(public translate: TranslateService) {
    this.creatLang();
  }

  creatLang(){
    this.userLangStored = localStorage.getItem('userLangStored');
    if (this.userLangStored) {
      this.translate.use(this.userLangStored);
    } else {
      this.userLang = navigator.language;
      if (this.userLang.indexOf('es') > -1) {
        this.translate.use('es');
      } else if (this.userLang.indexOf('en') > -1) {
        this.translate.use('en');
      } else if (this.userLang.indexOf('pt') > -1) {
        this.translate.use('pt');
      } else if (this.userLang.indexOf('fr') > -1) {
        this.translate.use('fr');
      } else {
        this.translate.use('en');
      }
      }
  }

  getLangClass(languageCode: string) {
    return {
      es: languageCode === 'es',
      en: languageCode === 'en',
      pt: languageCode === 'pt',
      fr: languageCode === 'fr',
    };
  }

  getLanguages() {
    return [
      { code: 'es', name: 'Español' },
      { code: 'rf', name: 'Français' },
      { code: 'en', name: 'English' },
      { code: 'pt', name: 'Portuguese' },
    ];
  }

  setLanguage(languageCode: string) {
    console.log("hola");
    this.translate.use(languageCode);
    localStorage.setItem('userLangStored', languageCode);
  }


}
