import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appValidateImage]',
})
export class ValidateImageDirective {

  constructor(private _element: ElementRef) {}

  @HostListener('error') onError() {
    this._element.nativeElement.src = 'assets/img/default-thumbnail.jpg';
  }
}
