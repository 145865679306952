import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-message',
  templateUrl: './cookies-message.component.html',
  styleUrls: ['./cookies-message.component.sass']
})
export class CookiesMessageComponent implements OnInit {
  isActive = true;
  
  constructor() { }

  ngOnInit() {
    // setTimeout(() => {
    //   if (localStorage.getItem('checkCookiesStatus') !== 'true') {
    //     this.isActive = true;
    //   }
    // }, 1000);
  }

  close() {
    this.isActive = false;
  }

  accept() {
    this.isActive = false;
    localStorage.setItem('checkCookiesStatus', 'true');
  }

}
