import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CookiesMessageComponent } from './cookies-message/cookies-message.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ServerImagePipe } from './server-image.pipe';
import { ValidateImageDirective } from './directives/validate-image.directive';

@NgModule({
  declarations: [
    NavigationComponent,
    FooterComponent, CookiesMessageComponent, ServerImagePipe, ValidateImageDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxSmartModalModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  exports: [
    NavigationComponent,
    FooterComponent,
    CookiesMessageComponent,
    ServerImagePipe,
    ValidateImageDirective,
  ]
})
export class UiModule { }
