import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UiModule } from './_core/modules/ui/ui.module';
import { RouterModule } from '@angular/router';
import {TooltipModule} from 'ng2-tooltip-directive';

import { NgxSmartModalModule } from 'ngx-smart-modal';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';

import { Ng2PanZoomModule, PanZoomConfig } from 'ng2-panzoom';
import { HttpModule } from './_core/modules/http/http.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    Ng2PanZoomModule,
    UiModule,
    TooltipModule,
    NgxSmartModalModule.forChild(),
    HttpClientModule,
    HttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  private panZoomConfig: PanZoomConfig = new PanZoomConfig;
 }
