import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'fundak-web-angular';
  translate;

  constructor(public _translate: TranslateService, private sanitizer: DomSanitizer, private route: ActivatedRoute) {
    this.translate = _translate;
    _translate.addLangs(['en', 'es', 'pt', 'fr']);
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let loc = (params && params.lang) || this.translate.getBrowserLang();

      this.translate.use(loc.match(/en|pt|es/) ? loc : 'en');
    })
  }

}

