import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'serverImage'
})
export class ServerImagePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return environment.API_ASSETS_URL + value;
  }

}
