import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home' , pathMatch: 'full' },
  { path: 'home', loadChildren: './home-page/home-page.module#HomePageModule' },
  { path: 'what-we-do', loadChildren: './what-we-do-page/what-we-do-page.module#WhatWeDoPageModule' },
  { path: 'work-with-us', loadChildren: './work-with-us-page/work-with-us-page.module#WorkWithUsPageModule' },
  { path: 'who-we-are', loadChildren: './who-we-are-page/who-we-are-page.module#WhoWeArePageModule' },
  { path: 'ten-years', loadChildren: './ten-years-page/ten-years-page.module#TenYearsPageModule' },
  { path: 'library', loadChildren: './library-page/library-page.module#LibraryPageModule' },
  { path: 'privacy-policy', loadChildren: './privacy-policy-page/privacy-policy-page.module#PrivacyPolicyPageModule' },
  { path: 'politica-privacidade', loadChildren: './privacy-policy-page/privacy-policy-page.module#PrivacyPolicyPageModule' },
  { path: 'country', loadChildren: './country-page/country-page.module#CountryPageModule' },
  { path: 'app', loadChildren: './app-page/app-page.module#AppPageModule' },
  { path: 'initiative', loadChildren: './initiative-page/initiative-page.module#InitiativePageModule' },
  { path: 'apps', loadChildren: './apps/apps.module#AppsModule' },
  { path: 'initiatives', loadChildren: './initiatives-page/initiatives-page.module#InitiativesPageModule' },
  { path: 'areas-of-work', loadChildren: './work-areas-page/work-areas-page.module#WorkAreasPageModule' },
  { path: 'area-of-work', loadChildren: './work-area-page/work-area-page.module#WorkAreaPageModule' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
